<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :class="{ row, error: v$.coupon.name.$errors.length }"
            :label="$t('coupon.name')"
            label-for="h-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.name" id="h-name" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.code.$errors.length }"
            :label="$t('coupon.code')"
            label-for="h-code"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.code" id="h-code" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.code.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.typeSelect.$errors.length }"
            :label="$t('coupon.value')"
            label-for="h-type"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <v-select
              id="h-type"
              v-model="coupon.typeSelect"
              :options="types"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.typeSelect.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.variantSelect.$errors.length }"
            :label="$t('coupon.variant')"
            label-for="h-type"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <v-select
              id="h-type"
              v-model="coupon.variantSelect"
              :options="variants"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.variantSelect.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.discountedValue.$errors.length }"
            :label="$t('coupon.discount')"
            label-for="h-discount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.discountedValue" id="h-discount" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.discountedValue.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.count.$errors.length }"
            :label="$t('coupon.couponCount')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              v-model="coupon.count"
              id="h-couponCount"
              type="number"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.count.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.used.$errors.length }"
            :label="$t('coupon.couponUsedCount')"
            label-for="h-couponUsedCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input
              v-model="coupon.used"
              id="h-couponUsedCount"
              type="number"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.used.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>

            <small v-if="orders.length > 0">
                {{ $t('coupon.orders') }}: <span v-for="order in orders" :key="order">
                    <a :href="$helper.getEditUrl('order', order.id)">{{ order.id }}</a>&nbsp;
                </span>
            </small>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.fromDate.$errors.length }"
            :label="$t('coupon.fromDate')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <datepicker
              class="form-input"
              id="fromDate"
              v-model="coupon.fromDate"
              format="dd. MM. yyyy | HH:mm"
              previewFormat="dd. MM. yyyy | HH:mm"
            ></datepicker>
            <div
              class="input-errors"
              v-for="error of v$.coupon.fromDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.toDate.$errors.length }"
            :label="$t('coupon.toDate')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <datepicker
              class="form-input"
              id="toDate"
              v-model="coupon.toDate"
              format="dd. MM. yyyy | HH:mm"
              previewFormat="dd. MM. yyyy | HH:mm"
            ></datepicker>

            <div
              class="input-errors"
              v-for="error of v$.coupon.toDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('coupon.dedication')"
            label-for="dedication"
            label-cols-md="2"
            content-cols-lg="4"
            v-if ="coupon.isGiftVoucher"
          >
            <b-form-textarea
              v-model="coupon.dedication"
              name="dedication"
            />
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('coupon.status')"
            label-for="h-enabled"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-checkbox v-model="coupon.onOff" name="enabled" switch />
          </b-form-group>

           <b-button v-if ="coupon.isGiftVoucher" class="mb-2" @click="genereateVoucher(true)" variant="primary">{{
            $t("voucher.generate")
          }}</b-button>

          <b-button v-if ="coupon.isGiftVoucher" class="ms-2 mb-2" @click="genereateVoucher(false)" variant="primary">{{
            $t("voucher.generateNoBG")
          }}</b-button>

          <b-button
            v-if ="coupon.isGiftVoucher"
            class="ms-2 mb-2"
            @click="deleteVoucher()"
            variant="secondary"
            >{{ $t("voucher.redelete") }}</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  props: {
    couponObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      coupon: {
        name: { required: this.$translateError('required') },
        code: { required: this.$translateError('required') },
        typeSelect: { required: this.$translateError('required') },
        variantSelect: { required: this.$translateError('required') },
        discountedValue: { required: this.$translateError('required'), decimal: this.$translateError('decimal') },
        fromDate: { required: this.$translateError('required') },
        toDate: { required: this.$translateError('required') },
        count: { required: this.$translateError('required') },
        used: { required: this.$translateError('required') },
      },
    };
  },
  created() {
    this.getEnumList("coupon_type", this.types);
    this.getEnumList("coupon_variant", this.variants);
    if (this.coupon.used > 0) {
        this.$Orders
            .getCollection({ params: { couponCode: this.coupon.code, pagination: false } }, 'coupon_order')
            .then((orderResponse) => {
            if (orderResponse.status === 200) {
                this.orders = orderResponse.data["hydra:member"];
            }
        })
    }
  },
  data() {
    return {
      coupon: this.couponObject,
      types: [],
      variants: [],
      orders: [],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.coupon.name,
          code: this.coupon.code,
          dedication: this.coupon.dedication,
          type: this.coupon.typeSelect.id,
          variant: this.coupon.variantSelect.id,
          discountedValue: this.coupon.discountedValue,
          count: parseInt(this.coupon.count),
          used: parseInt(this.coupon.used),
          fromDate: moment(this.coupon.fromDate).format(),
          toDate: moment(this.coupon.toDate).format(),
          onOff: this.coupon.onOff,
        };

        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Coupons,
                body,
                this.$t("coupon.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Coupons,
                this.coupon.id,
                body,
                this.$t("coupon.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/coupons`);
    },
    error() {
      this.$emit("clearAction");
    },
    genereateVoucher(withBg) {
      const name = withBg ? `gift_voucher_${this.coupon.code}` : `gift_voucher_${this.coupon.code}-nobg`
      const body = {
        name,
        resource: this.coupon['@id'],
        templateName: "GIFT_VOUCHER",
      };
      if (!withBg) {
        body.templateName = "GIFT_VOUCHER_WITHOUT_BG";
      }
      this.$Pdfs
        .getResourceByUrl({ url: `/pdfs/${name}` })
        .then((response) => {
          if (response.status === 200) {
            const link = document.createElement("a");
            link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
            link.target = "_blank";
            link.click();
          }
        })
        .catch(() => {
          this.$Pdfs.createResource({ body }).then((response) => {
            if (response.status === 201) {
              const link = document.createElement("a");
              link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
              link.target = "_blank";
              link.click();
            }
          });
        });
    },
    deleteVoucher() {
      this.deleteByUrl(
        this.$Pdfs,
        `/pdfs/gift_voucher_${this.coupon.code}`,
        this.$t("voucher.pdfBgDeleted")
      );
      this.deleteByUrl(
        this.$Pdfs,
        `/pdfs/gift_voucher_${this.coupon.code}-nobg`,
        this.$t("voucher.pdfNoBgDeleted")
      );
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
